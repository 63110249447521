/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiSummaryResponse } from '../models/AiSummaryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AiSummaryControllerService {

    /**
     * @returns AiSummaryResponse OK
     * @throws ApiError
     */
    public static getAiSummary({
        timestamp,
        rank,
    }: {
        timestamp: number,
        rank: number,
    }): CancelablePromise<AiSummaryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ai-summary/{timestamp}/rank/{rank}',
            path: {
                'timestamp': timestamp,
                'rank': rank,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
