import React, { useEffect, useState } from 'react';
import { Button, Layout, Modal, Popover, Tooltip } from 'antd';
import { useAtom, useAtomValue } from 'jotai';
import { Scrollbars } from 'react-custom-scrollbars';

import { MenuContent } from '@components/layout/MenuContent';
import { DARK_THEME, SIDE_NAV_WIDTH } from '@src/constants/constant';
import { NavigationTypes } from '@src/types/types';
import {
  themeCurrentThemeAtom,
  themeMobileNavAtom,
  themeNavCollapsedAtom,
} from '@src/store/theme';
import { BulbFilled, BulbOutlined } from '@ant-design/icons';
import useLogout from '@hooks/auth/useLogout';
import utils from '@src/utils/utils';
import { useQuery } from '@tanstack/react-query';
import { ApiService, TeambinderApiService } from '@src/api';
import { isMobile } from 'react-device-detect';
import Utils from '@src/utils/utils';
import { IcoMyActive, IcoMyInactive } from '@src/assets/svg';
import { useNavigate } from 'react-router-dom';

const { Sider } = Layout;
const { confirm } = Modal;

interface SideNavProps {
  hideGroupTitle?: string;
  customRouteInfo?: NavigationTypes[];
  routeInfo?: NavigationTypes;
}

export const SideNav = ({
  routeInfo,
  customRouteInfo,
  hideGroupTitle,
}: SideNavProps) => {
  const navigate = useNavigate();
  const isMobile = Utils.isMobile();
  const navCollapsed = useAtomValue(themeNavCollapsedAtom);
  const [currentTheme, setCurrentTheme] = useAtom(themeCurrentThemeAtom);

  const mode = () => {
    return utils.getColorContrast(
      currentTheme === DARK_THEME ? '#00000' : '#ffffff',
    );
  };

  const [openTooltip, setOpenTooltip] = useState(false);
  useEffect(() => {
    TeambinderApiService.getBinderMember().then((res) => {
      if (!res.data?.mobile && location.pathname !== '/app/my') {
        setTimeout(() => {
          setOpenTooltip(true);
        }, 2000);
      }
    });
  }, [location.pathname]);

  const handleClickSetTheme = () => {
    if (currentTheme === 'dark') {
      setCurrentTheme('light');
    } else {
      setCurrentTheme('dark');
    }
  };

  return (
    <Sider
      className={`side-nav`}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed && isMobile}
    >
      <Scrollbars autoHide>
        <MenuContent
          routeInfo={routeInfo}
          customRouteInfo={customRouteInfo}
          hideGroupTitle={hideGroupTitle}
        />
        <div className={'bottom-button-wrap'}>
          {/* <div className={'mr-4'}>
            <Button onClick={handleOnClick}>로그아웃</Button>
          </div> */}

          <div className={'mr-4'}>
            <Tooltip
              open={openTooltip}
              color="#444"
              title={
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '13px',
                  }}
                >
                  핸드폰 번호 등록하고 <br />
                  키워드 소식 알림 받아보세요!
                </div>
              }
            >
              <Button
                onClick={() => {
                  navigate('/app/my');
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                icon={
                  <IcoMyInactive style={{ width: '18px', height: '18px' }} />
                }
              />
            </Tooltip>
          </div>
          <div className={'mr-4'}>
            <Button
              onClick={handleClickSetTheme}
              icon={
                currentTheme === 'light' ? (
                  <BulbOutlined className="nav-icon mr-0" />
                ) : (
                  <BulbFilled className="nav-icon mr-0" />
                )
              }
            ></Button>
          </div>
        </div>
      </Scrollbars>
    </Sider>
  );
};
