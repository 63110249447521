/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasicResponseBinderAccessToken } from '../models/BasicResponseBinderAccessToken';
import type { BasicResponseGetBinderMemberResponse } from '../models/BasicResponseGetBinderMemberResponse';
import type { BasicResponseListBinderEntity } from '../models/BasicResponseListBinderEntity';
import type { BasicResponseListBinderKeyword } from '../models/BasicResponseListBinderKeyword';
import type { BasicResponsePersonalSettings } from '../models/BasicResponsePersonalSettings';
import type { BasicResponseString } from '../models/BasicResponseString';
import type { BasicResponseVoid } from '../models/BasicResponseVoid';
import type { BinderSignInViaKakaoCommand } from '../models/BinderSignInViaKakaoCommand';
import type { ModifyEmailRequest } from '../models/ModifyEmailRequest';
import type { ModifyPhoneNumberRequest } from '../models/ModifyPhoneNumberRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TeambinderApiService {

    /**
     * 바인더 이름 변경
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static editBinder({
        binderId,
        name,
    }: {
        binderId: string,
        name: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder/{binderId}',
            path: {
                'binderId': binderId,
            },
            query: {
                'name': name,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 키워드 수정
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static modifyBinderKeywords({
        binderId,
        keywordId,
        name,
        type,
        exclusions = '',
    }: {
        binderId: string,
        keywordId: string,
        name: string,
        type: 'NaverNews' | 'NaverBlog' | 'NaverCafe' | 'Youtube' | 'Twitter' | 'Instagram',
        exclusions?: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder/{binderId}/keywords/{keywordId}',
            path: {
                'binderId': binderId,
                'keywordId': keywordId,
            },
            query: {
                'name': name,
                'type': type,
                'exclusions': exclusions,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 키워드 삭제
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static deleteBinderKeyword({
        binderId,
        keywordId,
    }: {
        binderId: string,
        keywordId: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/binder/{binderId}/keywords/{keywordId}',
            path: {
                'binderId': binderId,
                'keywordId': keywordId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 순서 조정
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static reorderBinder({
        targetBinderId,
        prevBinderId = '',
    }: {
        targetBinderId: string,
        prevBinderId?: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder/reorder',
            query: {
                'targetBinderId': targetBinderId,
                'prevBinderId': prevBinderId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 설정 값 변경
     * setting-name (설정 이름)
     * * 카카오 알림 수신 여부: `notification.kakao.enabled`
     * * 이메일 수신 여부: `notification.email.enabled`
     *
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static modifyPersonalSettings({
        settingName,
        value,
    }: {
        /**
         * 설정 이름
         */
        settingName: string,
        /**
         * 설정 값
         */
        value: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder-app/members/me/settings/{setting-name}',
            path: {
                'setting-name': settingName,
            },
            query: {
                'value': value,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 휴대폰 번호 변경
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static modifyPhoneNumber({
        requestBody,
    }: {
        requestBody: ModifyPhoneNumberRequest,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder-app/members/me/phone-number',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * email 주소 번호 변경
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static modifyEmail({
        requestBody,
    }: {
        requestBody: ModifyEmailRequest,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder-app/members/me/email',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 목록 조회
     * @returns BasicResponseListBinderEntity OK
     * @throws ApiError
     */
    public static getBinderList(): CancelablePromise<BasicResponseListBinderEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binders',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 추가
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static addBinder({
        name,
    }: {
        name: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/binders',
            query: {
                'name': name,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 키워드 조회
     * @returns BasicResponseListBinderKeyword OK
     * @throws ApiError
     */
    public static getBinderKeywords({
        binderId,
    }: {
        binderId: string,
    }): CancelablePromise<BasicResponseListBinderKeyword> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder/{binderId}/keywords',
            path: {
                'binderId': binderId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 키워드 추가
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static addBinderKeywords({
        binderId,
        name,
        type,
        exclusions = '',
    }: {
        binderId: string,
        name: string,
        type: 'NaverNews' | 'NaverBlog' | 'NaverCafe' | 'Youtube' | 'Twitter' | 'Instagram',
        exclusions?: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/binder/{binderId}/keywords',
            path: {
                'binderId': binderId,
            },
            query: {
                'name': name,
                'type': type,
                'exclusions': exclusions,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 키워드 순서 변경
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static reorderKeyword({
        binderId,
        targetItemId,
        prevItemId = '',
    }: {
        binderId: string,
        targetItemId: string,
        prevItemId?: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/binder/{binderId}/keywords/reorder',
            path: {
                'binderId': binderId,
            },
            query: {
                'targetItemId': targetItemId,
                'prevItemId': prevItemId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * [바인더] 카카오 로그인 Token 발급 API
     * [로컬: 카카오 인증하기](http://localhost:8080/auth/binder/kakao/authorize) 접속
     *
     * @returns BasicResponseBinderAccessToken OK
     * @throws ApiError
     */
    public static signInViaKakao1({
        requestBody,
    }: {
        requestBody: BinderSignInViaKakaoCommand,
    }): CancelablePromise<BasicResponseBinderAccessToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/binder/kakao/token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * [바인더] 카카오 로그인 Token 발급 API
     * @returns BasicResponseString OK
     * @throws ApiError
     */
    public static getKakaoAccessToken({
        requestBody,
    }: {
        requestBody: BinderSignInViaKakaoCommand,
    }): CancelablePromise<BasicResponseString> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/binder/kakao/accessToken',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 정보 조회
     * @returns BasicResponseGetBinderMemberResponse OK
     * @throws ApiError
     */
    public static getBinderMember(): CancelablePromise<BasicResponseGetBinderMemberResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder/member',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 정보 및 설정값 조회
     * @returns BasicResponsePersonalSettings OK
     * @throws ApiError
     */
    public static getPersonalSettings(): CancelablePromise<BasicResponsePersonalSettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder-app/members/me/settings',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 바인더 삭제
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static removeBinder({
        binderId,
    }: {
        binderId: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/binders/{binderId}',
            path: {
                'binderId': binderId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
