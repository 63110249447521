import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoInbox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5 1.46v4.25l1.417-1.417M8.5 5.71 7.083 4.293"
      stroke="#706C6C"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.958 8.543c-2.833 0-2.833 1.268-2.833 2.833v.709c0 1.955 0 3.541 3.542 3.541h5.666c2.834 0 3.542-1.586 3.542-3.541v-.709c0-1.565 0-2.833-2.833-2.833-.709 0-.907.149-1.275.425l-.723.765a2.124 2.124 0 0 1-3.095 0l-.716-.765c-.368-.276-.566-.425-1.275-.425ZM3.542 8.543V5.709c0-1.423 0-2.6 2.125-2.805M13.458 8.543V5.709c0-1.423 0-2.6-2.125-2.805"
      stroke="#706C6C"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgIcoInbox;
