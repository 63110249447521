import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoMyInactive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 2.626c-7.13 0-12.917 5.787-12.917 12.917S8.37 28.459 15.5 28.459s12.917-5.786 12.917-12.916c0-7.13-5.787-12.917-12.917-12.917Zm0 3.875a3.87 3.87 0 0 1 3.875 3.875 3.87 3.87 0 0 1-3.875 3.875 3.87 3.87 0 0 1-3.875-3.875A3.87 3.87 0 0 1 15.5 6.501Zm0 18.342a9.3 9.3 0 0 1-7.75-4.16c.039-2.57 5.167-3.978 7.75-3.978 2.57 0 7.711 1.408 7.75 3.979a9.3 9.3 0 0 1-7.75 4.159Z"
      fill="#706C6C"
    />
  </svg>
);
export default SvgIcoMyInactive;
