import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoNewspaper = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={5.344}
      y={8.895}
      width={20.905}
      height={16.306}
      rx={0.836}
      fill="#000"
    />
    <path
      d="M2 5.967c0-.923.749-1.672 1.672-1.672h18.815c.923 0 1.672.749 1.672 1.672V21.02c0 .923-.749 1.672-1.672 1.672H3.672A1.672 1.672 0 0 1 2 21.02V5.968Z"
      fill="#fff"
    />
    <path
      d="M2 8.058v12.96c0 .924.749 1.673 1.672 1.673h18.815c.923 0 1.672-.749 1.672-1.672V8.058M2 8.058v-2.09c0-.924.749-1.673 1.672-1.673h18.815c.923 0 1.672.749 1.672 1.672v2.09M2 8.058h22.16"
      stroke="#000"
      strokeLinecap="round"
    />
    <circle cx={3.881} cy={6.176} r={0.627} fill="#000" />
    <circle cx={5.554} cy={6.176} r={0.627} fill="#000" />
    <circle cx={7.226} cy={6.176} r={0.627} fill="#000" />
    <rect
      x={4.509}
      y={10.148}
      width={9.198}
      height={9.616}
      rx={0.836}
      fill="#4403B5"
    />
    <path
      stroke="#000"
      strokeWidth={0.836}
      strokeLinecap="round"
      d="M15.798 12.657h5.435M15.798 14.748h5.435M15.798 16.838h3.763"
    />
  </svg>
);
export default SvgIcoNewspaper;
