import React from 'react';
import { Layout } from 'antd';
import ExternalRoutes from '@src/layouts/routes/ExternalRoutes';

export const ExternalLayout = () => {
  return (
    <Layout>
      <Layout className="app-container">
        <ExternalRoutes />
      </Layout>
    </Layout>
  );
};

export default React.memo(ExternalLayout);
