import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
  AiSummaryControllerService,
  AiSummaryResponse,
  Thumbnail,
} from '@src/api';
import { IcoAiRobot, IcoNewspaper } from '@src/assets/svg';
import '@src/assets/styles/ai-summary-news.scss';
import GoogleAdSmall from './GoogleAdSmall';
import GoogleAdLarge from './GoogleAdLarge';
import TaboolaWidget from './TaboolaWidget';

const AISummary = () => {
  const { timestamp, rank } = useParams<{
    timestamp?: string;
    rank?: string;
  }>();

  const [data, setData] = useState<AiSummaryResponse>();
  const [thumbnailImages, setThumbnailImages] = useState<Thumbnail[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const timeAgo = (timestamp: number): string => {
    const now = new Date().getTime();
    const difference = now - timestamp;
    const seconds = Math.floor(difference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return `${seconds}초 전`;
    if (minutes < 60) return `${minutes}분 전`;
    if (hours < 24) return `${hours}시간 전`;
    if (days < 7) return `${days}일 전`;
    if (weeks < 4) return `${weeks}주 전`;
    if (months < 12) return `${months}개월 전`;
    return `${years}년 전`;
  };

  useEffect(() => {
    const parsedTimestamp = timestamp ? parseInt(timestamp, 10) : 0;
    const parsedRank = rank ? parseInt(rank, 10) : 0;

    AiSummaryControllerService.getAiSummary({
      timestamp: parsedTimestamp,
      rank: parsedRank,
    })
      .then((r) => {
        setData(r);

        const thumbnails = r.thumbnails?.slice(0, 5).filter((x) => x?.image);
        setThumbnailImages(thumbnails?.slice(0, 2) ?? []);
      })
      .catch(() => {
        setError('API 요청에 실패했습니다. 잠시 후에 다시 시도해 주세요.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [timestamp, rank]);

  useEffect(() => {
    document.title = '시그널 실시간검색어';
    let link: HTMLLinkElement | null =
      document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link') as HTMLLinkElement;
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = '/favicon-signal.ico';
  }, []);

  if (loading) return null;
  if (error) return <p>{error}</p>;

  return (
    <>
      <div className="ai-summary-container">
        <div className="ai-summary-google-small-ads">
          <GoogleAdSmall />
        </div>
        <header>
          <span className="rank">실시간검색어 {rank}위</span>
          <h1 className="keyword">{data?.keyword}</h1>
        </header>

        {/* {thumbnailImages.length > 0 && (
          <div className="thumbnail-layout">
            <div className="thumbnail">
              {thumbnailImages.slice(0, 4).map((thumbnail, index) => (
                <img key={index} src={thumbnail.image} alt={thumbnail.title} />
              ))}
            </div>
          </div>
        )} */}

        <section className="ai-news">
          <h3>
            AI가 정리한 관련 뉴스 <IcoAiRobot />
          </h3>
          <div className="ai-news-content">
            <div className="summary">
              <ReactMarkdown>{data?.summaryContent || ''}</ReactMarkdown>
            </div>
          </div>
        </section>

        <section className="news-summary">
          <div className="ai-summary-google-large-ads">
            <GoogleAdLarge />
          </div>
          <h3>
            뉴스 모음 <IcoNewspaper />
          </h3>
          <div className="news-item">
            {data?.news?.map((news, index) => (
              <div
                className={`news-content ${index > 0 ? 'with-line' : ''}`}
                key={index}
              >
                <div className="news-text-container">
                  <h4>
                    <a
                      href={news.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      dangerouslySetInnerHTML={{ __html: news.title ?? '' }}
                    />
                    <span className="time-ago">
                      {timeAgo(news.publishedAt?.timestamp ?? 0)}
                    </span>
                  </h4>
                  {index === 0 && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: news.description ?? '',
                      }}
                    ></p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      <TaboolaWidget />
    </>
  );
};

export default AISummary;
