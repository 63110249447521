import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoAiRobot = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 13.937A6.788 6.788 0 0 1 9.788 7.15h8.424A6.788 6.788 0 0 1 25 13.937v1.275A6.788 6.788 0 0 1 18.212 22H9.788A6.788 6.788 0 0 1 3 15.212v-1.275Z"
      fill="#fff"
    />
    <path
      d="M13.785 7.15H9.788A6.788 6.788 0 0 0 3 13.936v1.275A6.788 6.788 0 0 0 9.788 22h8.424A6.788 6.788 0 0 0 25 15.212v-1.275a6.788 6.788 0 0 0-6.788-6.788h-4.427Zm0 0V5"
      stroke="#000"
      strokeLinecap="round"
    />
    <rect x={8} y={12} width={3.394} height={5.091} rx={1.697} fill="#4403B5" />
    <rect
      x={16.121}
      y={11.879}
      width={3.394}
      height={5.091}
      rx={1.697}
      fill="#4403B5"
    />
    <path
      d="M8 22h12v1.273c0 .937-.76 1.697-1.697 1.697H9.697C8.76 24.97 8 24.21 8 23.273V22ZM0 15.697a2.545 2.545 0 0 1 2.545-2.546h.849v5.091h-.849A2.546 2.546 0 0 1 0 15.697ZM28 15.697a2.545 2.545 0 0 0-2.546-2.546h-.848v5.091h.848A2.545 2.545 0 0 0 28 15.697Z"
      fill="#000"
    />
    <circle
      cx={14}
      cy={3.273}
      r={2}
      fill="#fff"
      stroke="#000"
      strokeLinecap="round"
    />
  </svg>
);
export default SvgIcoAiRobot;
