/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BasicResponseGetKeywordSearchResponse } from '../models/BasicResponseGetKeywordSearchResponse';
import type { BasicResponseGetMemberKeywordSearchesResult } from '../models/BasicResponseGetMemberKeywordSearchesResult';
import type { BasicResponseInstagramDataResponse } from '../models/BasicResponseInstagramDataResponse';
import type { BasicResponseKeywordNewsItem } from '../models/BasicResponseKeywordNewsItem';
import type { BasicResponseKeywordNewsItems } from '../models/BasicResponseKeywordNewsItems';
import type { BasicResponseListPlan } from '../models/BasicResponseListPlan';
import type { BasicResponseListPost } from '../models/BasicResponseListPost';
import type { BasicResponseListSubscriptionCardDTO } from '../models/BasicResponseListSubscriptionCardDTO';
import type { BasicResponseListUserSubscription } from '../models/BasicResponseListUserSubscription';
import type { BasicResponseMarketdoctorAccessToken } from '../models/BasicResponseMarketdoctorAccessToken';
import type { BasicResponseNaverBlogInventory } from '../models/BasicResponseNaverBlogInventory';
import type { BasicResponseNaverCafeArticleInventory } from '../models/BasicResponseNaverCafeArticleInventory';
import type { BasicResponseNaverKeywordSearchInventories } from '../models/BasicResponseNaverKeywordSearchInventories';
import type { BasicResponseNaverKeywordSearchInventory } from '../models/BasicResponseNaverKeywordSearchInventory';
import type { BasicResponseNaverNewsInventory } from '../models/BasicResponseNaverNewsInventory';
import type { BasicResponseObject } from '../models/BasicResponseObject';
import type { BasicResponseOptionalUserSubscription } from '../models/BasicResponseOptionalUserSubscription';
import type { BasicResponseRequestPresignedUrlResult } from '../models/BasicResponseRequestPresignedUrlResult';
import type { BasicResponseVoid } from '../models/BasicResponseVoid';
import type { BasicResponseYoutubeVideoInventory } from '../models/BasicResponseYoutubeVideoInventory';
import type { GetMemberKeywordSearchQuery } from '../models/GetMemberKeywordSearchQuery';
import type { KeywordNewsItemModification } from '../models/KeywordNewsItemModification';
import type { KeywordNewsItemRegistration } from '../models/KeywordNewsItemRegistration';
import type { RequestPresignedUrlCommand } from '../models/RequestPresignedUrlCommand';
import type { SignInViaKakaoCommand } from '../models/SignInViaKakaoCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiService {

    /**
     * 키워드 소식 수정
     * 키워드 소식 수정
     * @returns BasicResponseKeywordNewsItem OK
     * @throws ApiError
     */
    public static addKeywordNewsItem({
        id,
        requestBody,
    }: {
        /**
         * 키워드 소식 ID
         */
        id: string,
        requestBody: KeywordNewsItemModification,
    }): CancelablePromise<BasicResponseKeywordNewsItem> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/keyword-news/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 키워드 소식 삭제
     * 키워드 소식 삭제
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static deleteKeywordNewsItem({
        id,
    }: {
        /**
         * 키워드 소식 ID
         */
        id: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/keyword-news/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 탈퇴하기
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static withdrawMembership(): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/withdraw-membership',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 플랜 구독
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static subscribe({
        planId,
        cardId,
    }: {
        planId: string,
        cardId: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/subscription',
            query: {
                'planId': planId,
                'cardId': cardId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 플랜 구독 취소
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static cancelSubscription(): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/subscription',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 새로운 카드 정보 등록
     * @returns BasicResponseObject OK
     * @throws ApiError
     */
    public static requestBillingKey({
        customerKey = '',
        authKey = '',
    }: {
        customerKey?: string,
        authKey?: string,
    }): CancelablePromise<BasicResponseObject> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/subscription/card/billingKey',
            query: {
                'customerKey': customerKey,
                'authKey': authKey,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * File을 Upload하기 위한 인증된 URL 요청.
     * ### 입력값에 대한 설명
     * - **mediaType**: 업로드 할 파일의 확장자를 입력합니다.
     * 이 값은 S3에 업로드될 임의의 파일명에 붙일 확장자로 사용됩니다.
     * `jpg`, `jpeg`, `png` 중 하나의 값만 입력하도록 허용되어 있으며,
     * 실제 업로드 될 파일과 확장자의 일치여부 등 유효성 검증은 수행하지 않습니다.
     *
     * ### 출력값에 대한 설명
     * - **data.url**: Pre-signed URL 값 입니다.
     * 이 주소로 HTTP PUT 요청을 보내면서 실제 파일 업로드를 수행하게 됩니다.
     *
     * - **data.key**: 서버에 실제로 저장될 업로드 파일의 이름입니다.
     * 파일업로드 성공 이후, 클라이언트에서 이 파일명을 사용해야 할 경우가 있을 수 있기에 제공되는 값입니다.
     *
     * ### HTTP 요청 예시
     * 파일의 내용은 base64 인코딩해서 업로드 해야 합니다.
     * ```
     * PUT https://s3.ap-northeast-2.amazonaws.com/content-dev.marketdoctor.ai/temp/9488dcc8-ff45-4a44-8374-553763ad8545.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240210T140726Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Credential=AKIA3JJ7KGRFP5BV72IO%2F20240210%2Fap-northeast-2%2Fs3%2Faws4_request&X-Amz-Signature=572460a4225ae7615f26c7688d382bc58a2c00e5ee2ae684d97aa2fe29a60118
     *
     * "<file contents here: base64 인코딩된 파일 내용>"
     * ```
     *
     * ### 참고
     * S3에 File을 Upload하기 위해 인증된 Url을 요청하여, Client에서 인증받은 URL로 직접 File을 Upload 합니다. <br />
     * 참고자료: https://aws.amazon.com/ko/blogs/korea/uploading-to-amazon-s3-directly-from-a-web-or-mobile-application
     *
     * @returns BasicResponseRequestPresignedUrlResult OK
     * @throws ApiError
     */
    public static requestPresignedUrl({
        requestBody,
    }: {
        requestBody: RequestPresignedUrlCommand,
    }): CancelablePromise<BasicResponseRequestPresignedUrlResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/request-presigned-url',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 키워드 소식 목록
     * 키워드 소식 목록
     * @returns BasicResponseKeywordNewsItems OK
     * @throws ApiError
     */
    public static getKeywordNewsItems(): CancelablePromise<BasicResponseKeywordNewsItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/keyword-news',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 키워드 소식 등록
     * 키워드 소식 등록
     * @returns BasicResponseKeywordNewsItem OK
     * @throws ApiError
     */
    public static addKeywordNewsItem1({
        requestBody,
    }: {
        requestBody: KeywordNewsItemRegistration,
    }): CancelablePromise<BasicResponseKeywordNewsItem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/keyword-news',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 키워드 소식 등록
     * 키워드 소식 등록
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static reorderKeywordNewsItem({
        targetItemId,
        prevItemId = '',
    }: {
        targetItemId: string,
        prevItemId?: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/keyword-news/reorder',
            query: {
                'targetItemId': targetItemId,
                'prevItemId': prevItemId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * [데모] 키워드 소식 목록
     * [데모] 키워드 소식 목록
     * @returns BasicResponseKeywordNewsItems OK
     * @throws ApiError
     */
    public static getKeywordNewsItems1(): CancelablePromise<BasicResponseKeywordNewsItems> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/demo-keyword-news',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * [데모] 키워드 소식 등록
     * [데모] 키워드 소식 등록
     * @returns BasicResponseKeywordNewsItem OK
     * @throws ApiError
     */
    public static addKeywordNewsItem2({
        requestBody,
    }: {
        requestBody: KeywordNewsItemRegistration,
    }): CancelablePromise<BasicResponseKeywordNewsItem> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/demo-keyword-news',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 네이버 키워드 검색량 현황 조회
     * 백오피스/API 데이터/네이버 키워드 검색량
     * @returns BasicResponseGetMemberKeywordSearchesResult OK
     * @throws ApiError
     */
    public static getMemberKeywordSearches({
        requestBody,
    }: {
        requestBody: GetMemberKeywordSearchQuery,
    }): CancelablePromise<BasicResponseGetMemberKeywordSearchesResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/backoffice/keywordsearch/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 카카오 로그인 Token 발급 API
     * [로컬: 카카오 인증하기](http://localhost:8080/auth/kakao/authorize) 접속
     *
     * @returns BasicResponseMarketdoctorAccessToken OK
     * @throws ApiError
     */
    public static signInViaKakao({
        requestBody,
    }: {
        requestBody: SignInViaKakaoCommand,
    }): CancelablePromise<BasicResponseMarketdoctorAccessToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/kakao/token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 유튜브 검색 API
     * [참고 API docs](https://developers.google.com/youtube/v3/docs/search?hl=ko)
     *
     * @returns BasicResponseYoutubeVideoInventory OK
     * @throws ApiError
     */
    public static getYoutubeVideos({
        query,
        pageToken,
        display,
        exclude,
    }: {
        /**
         * 검색할 키워드
         */
        query?: string,
        /**
         * 페이지 토큰, 첫페이지는 빈문자열을 입력할 것. 다음페이지를 조회하려면? API 응답 결과의 `data.nextPageToken` 값을 입력할 것.
         */
        pageToken?: string,
        /**
         * 페이지 사이즈
         */
        display?: number,
        /**
         * 제외할 키워드 (',' 콤마 기호로 구분)
         */
        exclude?: string,
    }): CancelablePromise<BasicResponseYoutubeVideoInventory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/youtube-videos',
            query: {
                'query': query,
                'pageToken': pageToken,
                'display': display,
                'exclude': exclude,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 구독 가능한 플랜 조회
     * @returns BasicResponseListPlan OK
     * @throws ApiError
     */
    public static getSubscriptionPlans(): CancelablePromise<BasicResponseListPlan> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscription/plan',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 현재 구독 중인 플랜 조회
     * @returns BasicResponseOptionalUserSubscription OK
     * @throws ApiError
     */
    public static getSubscribedPlan(): CancelablePromise<BasicResponseOptionalUserSubscription> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscription/plan/my',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 구독 내역 조회
     * @returns BasicResponseListUserSubscription OK
     * @throws ApiError
     */
    public static getSubscriptionHistory(): CancelablePromise<BasicResponseListUserSubscription> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscription/history',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 카드 정보 조회
     * @returns BasicResponseListSubscriptionCardDTO OK
     * @throws ApiError
     */
    public static getPaymentCards(): CancelablePromise<BasicResponseListSubscriptionCardDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscription/card',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 네이버 뉴스 조회 API
     * @returns BasicResponseNaverNewsInventory OK
     * @throws ApiError
     */
    public static getNaverNews({
        query,
        display,
        start,
        exclude,
    }: {
        /**
         * 검색할 키워드
         */
        query?: string,
        /**
         * 페이지 사이즈 (1 ~ 100 사이의 값)
         */
        display?: number,
        /**
         * 페이지 번호 (1부터 시작)
         */
        start?: number,
        /**
         * 제외할 키워드 (',' 콤마 기호로 구분)
         */
        exclude?: string,
    }): CancelablePromise<BasicResponseNaverNewsInventory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/naver-news',
            query: {
                'query': query,
                'display': display,
                'start': start,
                'exclude': exclude,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 네이버 통합 검색어 트렌드 API ::
     * - https://www.notion.so/neverlish/01502e9a1ca64748b5f980f66da03538
     *
     * @returns BasicResponseNaverKeywordSearchInventory OK
     * @throws ApiError
     */
    public static getNaverKeywordSearch({
        keyword = '',
        timeUnit = '',
        type = '',
    }: {
        keyword?: string,
        timeUnit?: string,
        type?: string,
    }): CancelablePromise<BasicResponseNaverKeywordSearchInventory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/naver-keyword-search',
            query: {
                'keyword': keyword,
                'timeUnit': timeUnit,
                'type': type,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 네이버 카페 게시물 조회 API
     * @returns BasicResponseNaverCafeArticleInventory OK
     * @throws ApiError
     */
    public static getNaverCafeArticles({
        query,
        display,
        start,
        exclude,
    }: {
        /**
         * 검색할 키워드
         */
        query?: string,
        /**
         * 페이지 사이즈 (1 ~ 100 사이의 값)
         */
        display?: number,
        /**
         * 페이지 번호 (1부터 시작)
         */
        start?: number,
        /**
         * 제외할 키워드 (',' 콤마 기호로 구분)
         */
        exclude?: string,
    }): CancelablePromise<BasicResponseNaverCafeArticleInventory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/naver-cafe-articles',
            query: {
                'query': query,
                'display': display,
                'start': start,
                'exclude': exclude,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 네이버 블로그 조회 API
     * @returns BasicResponseNaverBlogInventory OK
     * @throws ApiError
     */
    public static getNaverBlogs({
        query,
        display,
        start,
        exclude,
    }: {
        /**
         * 검색할 키워드
         */
        query?: string,
        /**
         * 페이지 사이즈 (1 ~ 100 사이의 값)
         */
        display?: number,
        /**
         * 페이지 번호 (1부터 시작)
         */
        start?: number,
        /**
         * 제외할 키워드 (',' 콤마 기호로 구분)
         */
        exclude?: string,
    }): CancelablePromise<BasicResponseNaverBlogInventory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/naver-blogs',
            query: {
                'query': query,
                'display': display,
                'start': start,
                'exclude': exclude,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 페이지 접근 시 저장된 네이버 통합 검색어 트랜드 정보 처리 API
     *
     * @returns BasicResponseNaverKeywordSearchInventories OK
     * @throws ApiError
     */
    public static getMemberNaverKeywordSearch(): CancelablePromise<BasicResponseNaverKeywordSearchInventories> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/member-naver-keyword-search',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @deprecated
     * 인스타그램 검색 API
     * @returns BasicResponseListPost OK
     * @throws ApiError
     */
    public static getInstagramContents({
        query,
        display,
    }: {
        /**
         * 검색할 키워드
         */
        query?: string,
        /**
         * 페이지 사이즈
         */
        display?: number,
    }): CancelablePromise<BasicResponseListPost> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/instagram-posts',
            query: {
                'query': query,
                'display': display,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 인스타그램 검색 API - New
     * @returns BasicResponseInstagramDataResponse OK
     * @throws ApiError
     */
    public static getInstagramContentsNew({
        query,
        pageToken = '',
    }: {
        /**
         * 검색할 키워드
         */
        query?: string,
        pageToken?: string,
    }): CancelablePromise<BasicResponseInstagramDataResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/instagram-posts-new',
            query: {
                'query': query,
                'pageToken': pageToken,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * [데모] 네이버 뉴스 조회 API
     * @returns BasicResponseNaverNewsInventory OK
     * @throws ApiError
     */
    public static getNaverNews1({
        query = '',
        display = 10,
        start = 1,
    }: {
        query?: string,
        display?: number,
        start?: number,
    }): CancelablePromise<BasicResponseNaverNewsInventory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/demo-naver-news',
            query: {
                'query': query,
                'display': display,
                'start': start,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 네이버 키워드 검색량 현황 조회 (상세)
     * 백오피스/API 데이터/네이버 키워드 검색량
     * @returns BasicResponseGetKeywordSearchResponse OK
     * @throws ApiError
     */
    public static getMemberKeywordSearch({
        id,
    }: {
        id: string,
    }): CancelablePromise<BasicResponseGetKeywordSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/backoffice/keywordsearch/search/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 카드 정보 삭제
     * @returns BasicResponseObject OK
     * @throws ApiError
     */
    public static deleteBillingKey({
        id,
    }: {
        id: string,
    }): CancelablePromise<BasicResponseObject> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/subscription/card/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * 사용자 검색 키워드 이력 삭제
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static deleteMemberNaverKeywordSearch({
        id,
    }: {
        id: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/member-naver-keyword-search/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * [데모] 키워드 소식 등록
     * [데모] 키워드 소식 등록
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static deleteKeywordNewsItem1({
        id,
    }: {
        id: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/demo-keyword-news/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
