import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { FallbackProps } from 'react-error-boundary';

import { Loading } from '@components/shared';
import PageError from '@components/shared/PageError';
import AsyncBoundary from '@src/components/shared/AsyncBoundary';

import AISummary from '@src/views/external/aiSummary/AISummary';

export const ExternalRoutes = () => {
  const location = useLocation();

  return (
    <AsyncBoundary
      ErrorFallback={(fallbackProps: FallbackProps) => (
        <PageError fallbackProps={fallbackProps} />
      )}
      SuspenseFallback={<Loading cover="content" />}
      resetKeys={[location.pathname]}
    >
      <Routes>
        <Route
          path="ai-summary/:timestamp/rank/:rank"
          element={<AISummary />}
        />
      </Routes>
    </AsyncBoundary>
  );
};

export default React.memo(ExternalRoutes);
