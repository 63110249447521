import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoSearchTitle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={16} cy={16} r={16} fill="#2E3338" />
    <path
      d="M14.358 15.818h-2.272V9.273h2.272v6.545Zm.014 4.33H7.65v1.05H5.435v-2.704H12.1v-.419H5.407v-1.739h8.965v3.812Zm.284 2.208H5.435v-1.768h9.221v1.768ZM8.083 10.515a7.086 7.086 0 0 1-.405 2.385 4.858 4.858 0 0 1-1.25 1.91c-.567.54-1.296.927-2.186 1.164l-1.1-1.782c.738-.203 1.335-.497 1.789-.88.454-.383.776-.814.965-1.292.194-.478.294-.98.298-1.505v-.994h1.889v.994Zm.49 0a3.84 3.84 0 0 0 .262 1.462c.185.45.495.855.93 1.214.436.355 1.015.627 1.74.817l-1.108 1.753c-.876-.237-1.59-.613-2.144-1.129a4.676 4.676 0 0 1-1.207-1.817 6.777 6.777 0 0 1-.376-2.3v-.994h1.903v.994Zm13.967-.511c0 1.169-.223 2.212-.668 3.13-.444.919-1.11 1.7-1.994 2.343-.885.644-1.976 1.143-3.273 1.498l-.866-1.782c.984-.25 1.808-.582 2.47-.994.663-.416 1.153-.885 1.47-1.405a3.228 3.228 0 0 0 .49-1.69v-1.1h2.37Zm-.788 1.782H16.47v-1.782h5.282v1.782Zm5.14 4.756h-2.265v-7.24h2.264v7.24Zm-1.995-2.45H22.66v-1.845h2.237v1.846Zm1.994 8.186h-8.81v-5.225h8.81v5.225Zm-6.58-1.768h4.323v-1.69H20.31v1.69Z"
      fill="#24C185"
    />
  </svg>
);
export default SvgIcoSearchTitle;
