import { MAIN_PATH } from '@src/constants/constant';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { authAtom } from '@src/store/auth';

const useLogout = () => {
  const navigate = useNavigate();
  const [authInfo, setAuthInfo] = useAtom(authAtom);

  const logout = useCallback(async () => {
    setAuthInfo(undefined);
  }, []);

  return logout;
};

export default useLogout;
