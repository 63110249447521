import { Routes, Route } from 'react-router-dom';
import { Loading } from '@components/shared';
import AsyncBoundary from '@components/shared/AsyncBoundary';
import { FallbackProps } from 'react-error-boundary';
import PageError from '@components/shared/PageError';
import Login from '@views/main/Login';
import LoginCommand from '@views/main/LoginCommand';
import { Redirect } from '@src/components/shared/Redirect';

export const MainRoutes = () => {
  return (
    <AsyncBoundary
      ErrorFallback={(fallbackProps: FallbackProps) => (
        <PageError fallbackProps={fallbackProps} />
      )}
      SuspenseFallback={<Loading cover="content" />}
    >
      <Routes>
        <Route path={`auth/oauth/callback`} element={<Login />} />
        <Route
          path={`auth/oauth/callback/command`}
          element={<LoginCommand />}
        />
        {/* <Route path={`error-1`} element={<Error404 />} />
        <Route path={`error-2`} element={<Error500 />} /> */}
        <Route path={`*`} element={<Redirect />} />
      </Routes>
    </AsyncBoundary>
  );
};

export default MainRoutes;
