import React, { useEffect, useState } from 'react';
import { APP_PREFIX_PATH } from '@src/constants/constant';
import { Button, Tooltip } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import Utils from '@src/utils/utils';
import { useChannelTalk } from '@hooks/channelTalk/useChannelTalk';
import { useMainInfo } from '@hooks/main';
import { IcoLogoOnly, IcoMenu, IcoMyActive } from '@src/assets/svg';
import { useAtom } from 'jotai';
import { themeCurrentThemeAtom, themeMobileNavAtom } from '@src/store/theme';
import { TeambinderApiService } from '@src/api';
import { MenuOutlined } from '@ant-design/icons';

export const Footer = () => {
  const isMobile = Utils.isMobile();

  if (!isMobile) {
    return null;
  }
  return (
    <footer className={`footer`}>
      <MobileFooter />
    </footer>
  );
};

const MobileFooter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const channelTalk = useChannelTalk();
  const [mainInfo, _] = useMainInfo();
  const [mobileNav, setMobileNav] = useAtom(themeMobileNavAtom);
  const [currentTheme, setCurrentTheme] = useAtom(themeCurrentThemeAtom);

  const isVisibleChannel = mainInfo.isVisibleChannelTalk;

  const isActive = (path: string) => {
    return location.pathname.includes(APP_PREFIX_PATH + path);
  };

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const path = e.currentTarget.dataset['path'];

    if (!path) {
      return;
    }

    navigate(path);
  };

  const handleOnClickChannelTalk = () => {
    if (isVisibleChannel) {
      channelTalk.hide();
    } else {
      channelTalk.show();
    }
  };

  const [openTooltip, setOpenTooltip] = useState(false);
  useEffect(() => {
    TeambinderApiService.getBinderMember().then((res) => {
      if (!res.data?.mobile && location.pathname !== '/app/my') {
        setTimeout(() => {
          setOpenTooltip(true);
        }, 2000);
      }
    });
  }, [location.pathname]);
  useEffect(() => {
    if (location.pathname === '/app/my') {
      setOpenTooltip(false);
    }
  }, [location.pathname]);

  return (
    <div className={'wrap mobile'}>
      <div className={'item-wrap'}>
        <Button
          type={'text'}
          className={`item ${
            isVisibleChannel === false && isActive('/analysis') ? 'active' : ''
          }`}
          onClick={() => {
            if (mobileNav) {
              setMobileNav(false);
            } else {
              setMobileNav(true);
            }
          }}
        >
          <MenuOutlined
            className={'footer-icon'}
            style={{
              fontSize: 20,
              color: currentTheme === 'dark' ? '#aaa' : 'black',
            }}
          />
        </Button>

        <Button
          type={'text'}
          className={`item ${isVisibleChannel === true ? 'active' : ''}`}
          onClick={handleOnClick}
          data-path="/app"
        >
          <IcoLogoOnly
            className={'footer-icon'}
            style={{
              filter:
                location.pathname !== '/app'
                  ? 'grayscale(1)'
                  : 'brightness(1.3)',
            }}
          />
        </Button>

        <Button
          type={'text'}
          className={`item ${
            isVisibleChannel === false && isActive('/my-news') ? 'active' : ''
          }`}
          onClick={handleOnClick}
          data-path="/app/my"
        >
          <Tooltip
            open={openTooltip}
            color="#444"
            placement="left"
            title={
              <div
                style={{
                  textAlign: 'center',
                  fontSize: '13px',
                }}
              >
                핸드폰 번호 등록하고 <br />
                키워드 소식 알림 받아보세요!
              </div>
            }
          >
            <IcoMyActive
              className={'footer-icon'}
              style={{
                filter:
                  location.pathname !== '/app/my'
                    ? 'grayscale(1)'
                    : 'brightness(2)',
                width: '27px',
                height: '27px',
              }}
            />
          </Tooltip>
        </Button>
      </div>
    </div>
  );
};
