import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoMyActive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5 2.583C8.37 2.583 2.583 8.37 2.583 15.5S8.37 28.416 15.5 28.416 28.417 22.63 28.417 15.5c0-7.13-5.787-12.917-12.917-12.917Zm0 3.875a3.87 3.87 0 0 1 3.875 3.875 3.87 3.87 0 0 1-3.875 3.875 3.87 3.87 0 0 1-3.875-3.875A3.87 3.87 0 0 1 15.5 6.458Zm0 18.342a9.3 9.3 0 0 1-7.75-4.16c.039-2.57 5.167-3.978 7.75-3.978 2.57 0 7.711 1.408 7.75 3.979A9.301 9.301 0 0 1 15.5 24.8Z"
      fill="#4403B5"
    />
  </svg>
);
export default SvgIcoMyActive;
