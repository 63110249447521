import React, { useEffect, useState } from 'react';
import { Button, Card, Input, Space, Switch } from 'antd';
import Scrollbars from 'react-custom-scrollbars';
import { ApiService, SnsApiService, TeambinderApiService } from '@src/api';
import { useAtom } from 'jotai';
import { themeCurrentThemeAtom } from '@src/store/theme';
import Utils from '@src/utils/utils';
import { useQuery } from '@tanstack/react-query';
import useLogout from '@src/hooks/auth/useLogout';
import {
  ABOUT_US_URL,
  KAKAO_REDIRECT_URL_COMMAND,
} from '@src/constants/constant';

const SettingsMy = () => {
  const { data: userResponse, refetch: refetchSetting } = useQuery(
    ['member'],
    TeambinderApiService.getPersonalSettings,
  );
  const [currentTheme, setCurrentTheme] = useAtom(themeCurrentThemeAtom);
  const isMobile = Utils.isMobile();
  const handleClickSetTheme = () => {
    if (currentTheme === 'dark') {
      setCurrentTheme('light');
    } else {
      setCurrentTheme('dark');
    }
  };
  const _logout = useLogout();
  const logout = async () => {
    _logout();
    window.location.href = ABOUT_US_URL;
  };

  const [normalizedPhoneNumber, setNormalizedPhoneNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  useEffect(() => {
    if (userResponse?.data?.notification?.email?.email) {
      setEmail(userResponse?.data?.notification?.email?.email);
    }
    if (userResponse?.data?.notification?.kakao?.mobile) {
      const phone = userResponse?.data?.notification?.kakao?.mobile;
      const normalized = '0' + phone.split(/[- ]/).slice(1).join(''); // "+82 10-3333-5555" -> "01033335555"
      setPhoneNumber(normalized);
      setNormalizedPhoneNumber(normalized);
    }
  }, [userResponse?.data]);

  return (
    <Scrollbars>
      <div
        style={{
          padding: isMobile ? '20px' : '',
        }}
      >
        <h1
          style={{
            color: '#4403B5',
            marginBottom: '20px',
            fontWeight: 'bold',
          }}
        >
          설정
        </h1>
        {userResponse?.data?.username && (
          <h2>안녕하세요! {userResponse?.data?.username || ''}님!</h2>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '50px',
            gap: '50px',
            paddingLeft: 0,
            fontSize: '16px',
          }}
        >
          <div>
            <h4 style={{ display: 'inline-block' }}>
              카카오로 알림 받기
              {userResponse?.data?.notification?.kakao?.mobile && (
                <Switch
                  checked={userResponse?.data?.notification?.kakao?.enabled}
                  style={{ marginLeft: '10px' }}
                  onClick={async (checked) => {
                    await TeambinderApiService.modifyPersonalSettings({
                      settingName: 'notification.kakao.enabled',
                      value: String(checked),
                    });
                    refetchSetting();
                  }}
                />
              )}
            </h4>
            <div>
              설정한 키워드에 새로운 소식이 생기면 카카오 알림톡으로
              알려드립니다.
              <br />
              {!userResponse?.data?.hasPhoneNumber && (
                <>
                  등록된 전화번호가 없으면 카카오 알림톡 발송이 불가합니다.
                  <br />
                  <Input.Search
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    enterButton={
                      <Button disabled={phoneNumber === normalizedPhoneNumber}>
                        저장
                      </Button>
                    }
                    placeholder="핸드폰 번호"
                    style={{ marginTop: '12px', maxWidth: '460px' }}
                    onSearch={async (phone) => {
                      if (!/^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/.test(phone)) {
                        alert('핸드폰 번호 형식이 올바르지 않습니다.');
                        return;
                      }
                      await TeambinderApiService.modifyPhoneNumber({
                        requestBody: { phoneNumber: phone },
                      });
                      refetchSetting();
                    }}
                  />
                </>
              )}
              {!userResponse?.data?.notification?.kakao?.mobile &&
                userResponse?.data?.hasPhoneNumber && (
                  <>
                    등록된 전화번호가 없으면 카카오 알림톡 발송이 불가합니다.{' '}
                    <br />
                    <Button size="small" type="link" style={{ padding: 0 }}>
                      <a
                        href={`https://kauth.kakao.com/oauth/authorize?client_id=7fa35d903c130394963af980d96edd38&redirect_uri=${encodeURI(
                          KAKAO_REDIRECT_URL_COMMAND,
                        )}&response_type=code`}
                      >
                        핸드폰 번호 등록하기
                      </a>
                    </Button>
                  </>
                )}
            </div>
          </div>
          <div>
            <h4 style={{ display: 'inline-block' }}>
              이메일로 알림 받기
              <Switch
                checked={userResponse?.data?.notification?.email?.enabled}
                style={{ marginLeft: '10px' }}
                onClick={async (checked) => {
                  await TeambinderApiService.modifyPersonalSettings({
                    settingName: 'notification.email.enabled',
                    value: String(checked),
                  });
                  refetchSetting();
                }}
              />
            </h4>
            <div>
              설정한 키워드에 새로운 소식이 생기면 이메일로 알려드립니다.
              <br />
              <Input.Search
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                enterButton={
                  <Button
                    disabled={
                      email ===
                      (userResponse?.data?.notification?.email?.email || '')
                    }
                  >
                    저장
                  </Button>
                }
                placeholder="이메일 주소"
                style={{ marginTop: '12px', maxWidth: '460px' }}
                onSearch={async (email) => {
                  if (
                    !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,10})$/.test(
                      email,
                    )
                  ) {
                    alert('이메일 형식이 올바르지 않습니다.');
                    return;
                  }
                  await TeambinderApiService.modifyEmail({
                    requestBody: { email },
                  });
                  refetchSetting();
                }}
              />
            </div>
          </div>
          <div
            role="presentation"
            onClick={handleClickSetTheme}
            style={{
              cursor: 'pointer',
              gap: '30px',
            }}
          >
            <h4 style={{ display: 'inline-block' }}>
              배경화면 테마
              <Switch
                checked={currentTheme === 'dark'}
                style={{ marginLeft: '10px' }}
              />
            </h4>
          </div>
          <div>
            <h4>문의</h4>
            <a href="mailto:mysignal.bob@gmail.com">mysignal.bob@gmail.com</a>
          </div>
          <div
            role="presentation"
            onClick={logout}
            style={{ cursor: 'pointer' }}
          >
            로그아웃
          </div>
        </div>
      </div>
    </Scrollbars>
  );
};

export default SettingsMy;
