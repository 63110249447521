import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Input,
  InputRef,
  Modal,
  Row,
  Spin,
  Typography,
} from 'antd';
import { useKakaoLogin } from '@hooks/auth';
import { SearchOutlined } from '@ant-design/icons';
import { useSignInToken } from '@hooks/auth/useFirebase';
import { useMutationKakaoLogin } from '@hooks/api/auth';
import {
  ApiService,
  BasicResponseMarketdoctorAccessToken,
  TeambinderApiService,
} from '@src/api';
import {
  API_SUCCESS_CODE,
  APP_HOME_PATH,
  KAKAO_REDIRECT_URL,
  KAKAO_REDIRECT_URL_COMMAND,
  MAIN_PATH,
} from '@src/constants/constant';
import { useNavigate } from 'react-router-dom';
import { LoadingFull } from '@components/shared';
import KakaoLogin from '@views/main/KakaoLogin';
import { Logo } from '@components/layout';
import { useAtom } from 'jotai';
import { authAtom } from '@src/store/auth';

const { Title } = Typography;
const { confirm } = Modal;

const backgroundStyle = {
  backgroundImage: 'url(/img/others/img-17.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

interface LoginFormProps {
  showLoading?: boolean;
  extra?: string | ReactElement;
  loading?: boolean;
  showMessage?: string;
  message?: string;
}

const LoginCommand = (props: LoginFormProps) => {
  const navigate = useNavigate();
  const [authInfo, setAuthInfo] = useAtom(authAtom);

  const signIn = useSignInToken();

  const [kakaoLoginCode, setKakaoLoginCode] = useState(() => {
    const params = new URL(window.location.href).searchParams;
    //params에 저장된 파라미터 안에서 'code'의 값을 가져옴
    const code = params.get('code');

    return code;
  });
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!kakaoLoginCode) {
      confirm({
        title: '안내',
        content: '잘못된 접근 입니다.',
        okText: '확인',
        okCancel: false,
        onOk() {
          navigate(MAIN_PATH);
        },
      });

      return;
    }

    setLoading(true);
    (async () => {
      const response = await TeambinderApiService.getKakaoAccessToken({
        requestBody: {
          code: kakaoLoginCode,
          redirectUri: KAKAO_REDIRECT_URL_COMMAND,
        },
      });
      if (!response.data) {
        window.alert('잘못된 접근입니다.');
        window.location.href = '/app';
        return;
      }
      Kakao.Auth.setAccessToken(response.data);

      // TODO: scope 확인해서 phone_number가 없을 때만 동의 요청
      const scopeResponse = await Kakao.API.request({
        url: '/v2/user/scopes',
      });
      const isPhoneNumberAgreed = scopeResponse.scopes.find(
        (scope: any) => scope.id === 'phone_number' && scope.agreed,
      );
      const meResponse = await Kakao.API.request({
        url: '/v2/user/me',
      });
      const hasPhoneNumber = meResponse.kakao_account.has_phone_number;
      if (!hasPhoneNumber) {
        confirm({
          content: '핸드폰 번호를 가져올 수 없습니다.',
          onOk() {
            window.location.href = '/app';
          },
          cancelButtonProps: { style: { display: 'none' } },
        });
        return;
      }

      if (isPhoneNumberAgreed) {
        const phoneNumber = meResponse?.kakao_account?.phone_number;
        if (!phoneNumber) {
          confirm({
            content: '핸드폰 번호 등록에 실패했습니다.',
            onOk() {
              window.location.href = '/app';
            },
            cancelButtonProps: { style: { display: 'none' } },
          });
        } else {
          await TeambinderApiService.modifyPhoneNumber({
            requestBody: {
              phoneNumber,
            },
          });
          confirm({
            content: '핸드폰 번호가 등록되었습니다.',
            onOk() {
              window.location.href = '/app/my';
            },
            cancelButtonProps: { style: { display: 'none' } },
          });
        }
      } else {
        Kakao.Auth.authorize({
          scope: 'phone_number',
          redirectUri: KAKAO_REDIRECT_URL_COMMAND,
        });
      }
    })();
  }, [kakaoLoginCode]);

  return (
    <div className={'login-container'}>
      <div className={'login-wrap'}>
        <Logo mobileLogo={false} />
        <Spin></Spin>
      </div>
    </div>
  );
};

export default LoginCommand;
