import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoLogoOnly = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={16} width={29.463} height={16} rx={8} fill="#727CFC" />
    <rect width={25.6} height={16} rx={8} fill="#08D6F2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.5A2.5 2.5 0 0 1 2.5 0h5.3A8 8 0 0 1 8 15.998v.005A8 8 0 0 1 7.8 32H2.5A2.5 2.5 0 0 1 0 29.5v-27Z"
      fill="#4403B5"
    />
  </svg>
);
export default SvgIcoLogoOnly;
