import React from 'react';

import { Layout } from 'antd';
import {
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { useAtom } from 'jotai';

import { Logo } from '@components/layout';
import {
  DARK_THEME,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from '@src/constants/constant';
import utils from '@src/utils/utils';
import {
  themeCurrentThemeAtom,
  themeMobileNavAtom,
  themeNavCollapsedAtom,
} from '@src/store/theme';

const { Header } = Layout;

interface HeaderNavProps {
  isMobile: boolean;
}

export const HeaderNav = ({ isMobile }: HeaderNavProps) => {
  const [navCollapsed, setNavCollapsed] = useAtom(themeNavCollapsedAtom);
  const [mobileNav, setMobileNav] = useAtom(themeMobileNavAtom);
  const [currentTheme, setCurrentTheme] = useAtom(themeCurrentThemeAtom);

  const onToggle = () => {
    if (!isMobile) {
      setNavCollapsed(!navCollapsed);
    } else {
      setMobileNav(!mobileNav);
    }
  };

  const mode = () => {
    return utils.getColorContrast(
      currentTheme === DARK_THEME ? '#00000' : '#ffffff',
    );
  };
  const navMode = mode();

  const getNavWidth = () => {
    if (isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  return (
    <Header className={`app-header ${navMode}`}>
      <div className={`app-header-wrapper`}>
        <Logo logoType={navMode} mobileLogo={false} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          {isMobile && (
            <div className={`${isMobile ? 'nav-right' : 'nav-left'}`}>
              <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                <li
                  aria-hidden="true"
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {isMobile ? (
                    <MenuOutlined
                      className="nav-icon"
                      style={{
                        color: currentTheme === 'dark' ? '#aaa' : 'black',
                      }}
                    />
                  ) : navCollapsed ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuOutlined
                      className="nav-icon"
                      style={{
                        color: currentTheme === 'dark' ? '#aaa' : 'black',
                      }}
                    />
                  )}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </Header>
  );
};
