import React, { ReactElement, ReactNode } from 'react';
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { DropdownProps } from 'antd/lib/dropdown/dropdown';
declare type OverlayFunc = () => React.ReactElement;
interface _EllipsisDropdownProps
  extends Omit<DropdownProps, 'trigger' | 'menu'> {
  trigger?: 'click' | 'hover' | 'contextMenu';
  menu?: ReactElement | OverlayFunc;
}

type EllipsisDropdownProps = Pick<
  _EllipsisDropdownProps,
  'placement' | 'menu' | 'trigger'
>;

const EllipsisDropdown = ({
  trigger = 'click',
  menu,
  placement = 'bottomRight',
}: EllipsisDropdownProps) => {
  const _menu = menu ?? <Menu />;
  return (
    <Dropdown overlay={_menu} placement={placement} trigger={[trigger]}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className="ellipsis-dropdown" onClick={(e) => e.stopPropagation()}>
        <EllipsisOutlined />
      </div>
    </Dropdown>
  );
};

export default EllipsisDropdown;
