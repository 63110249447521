import React, { useEffect } from 'react';

declare global {
  interface Window {
    adsbygoogle: unknown[] | undefined;
  }
}

const GoogleAdSmall: React.FC = () => {
  useEffect(() => {
    // 구글 광고 로딩 스크립트
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('Ad push error:', e);
    }
  }, []);

  return (
    <div
      style={{
        display: 'inline-block',
        textAlign: 'center',
        maxWidth: '320px',
        overflow: 'hidden',
      }}
    >
      <ins
        className="adsbygoogle"
        style={{ display: 'inline-block', width: '320px', height: '50px' }}
        data-ad-client="ca-pub-7038131727768741"
        data-ad-slot="8048142417"
        data-auto-ad-size="false"
      ></ins>
    </div>
  );
};

export default GoogleAdSmall;
