import React, { useCallback } from 'react';

import { Modal } from 'antd';

import { ApiError } from '@src/api';
import { API_SUCCESS_CODE, KAKAO_REDIRECT_URL } from '@src/constants/constant';
import useLogout from '@hooks/auth/useLogout';

const { confirm } = Modal;

type ErrorCodeType = number | string;
type ErrorHandlersType = {
  [key: ErrorCodeType]: {
    [key: string]: (apiError?: ApiError) => void;
  };
};

interface apiErrorPros {
  handlers: ErrorHandlersType;
}

//중복 호출을 막기 위한 code list
const apiErrorCurrentCodes: Array<number | string> = [];
const appendErrorCode = (code: number | string) => {
  const findIndex = apiErrorCurrentCodes.findIndex(
    (currentCode) => currentCode === code,
  );
  if (findIndex <= -1) {
    apiErrorCurrentCodes.push(code);
    return true;
  }

  return false;
};

const removeErrorCode = (code: ErrorCodeType) => {
  const findIndex = apiErrorCurrentCodes.findIndex(
    (currentCode) => currentCode === code,
  );
  if (findIndex > -1) {
    apiErrorCurrentCodes.splice(findIndex, 1);
  }
};

const commonConfirm = (
  errorCode: ErrorCodeType,
  content: string,
  onOk?: () => void,
  onCancel?: () => void,
) => {
  confirm({
    type: 'warning',
    title: '안내',
    content: content,
    okText: '확인',
    cancelText: '취소',
    onCancel() {
      removeErrorCode(errorCode);
      onCancel && onCancel();
    },
    onOk() {
      removeErrorCode(errorCode);

      onOk && onOk();
    },
  });
};

export const useApiError = (handlers?: apiErrorPros) => {
  const logout = useLogout();

  const handler401 = useCallback(async () => {
    commonConfirm(401, '로그인이 필요합니다.', () => {
      logout();
      const authRedirecrtUrl = KAKAO_REDIRECT_URL;
      window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=7fa35d903c130394963af980d96edd38&redirect_uri=${authRedirecrtUrl}&response_type=code`;
    });
  }, []);

  const handler403 = useCallback(() => {
    // 컴포넌트의 상황에 맞게 처리 로직을 작성
    commonConfirm(403, '인증이 실패하였습니다.', () => {
      console.log('403 확인');
    });
  }, []);

  const handler500 = useCallback(() => {
    // 컴포넌트의 상황에 맞게 처리 로직을 작성
    // commonConfirm(500, '알수없는 오류가 발생하였습니다.', () => {
    //   console.log('403 확인');
    // });
  }, []);

  const handlerBusinessError = useCallback((apiError?: ApiError) => {
    console.log(apiError);
    const ApiResponseError = apiError?.body;
    // 컴포넌트의 상황에 맞게 처리 로직을 작성
    commonConfirm(ApiResponseError.code, ApiResponseError.message, () => {
      console.log(`${ApiResponseError.code} 확인`);
    });
  }, []);

  const defaultHandlers: ErrorHandlersType = {
    400: {},
    404: {},
    401: {
      default: handler401,
    },
    403: {
      default: handler403,
    },
    500: {
      default: handler500,
    },
    502: {},
    503: {},

    businessError: {
      default: handlerBusinessError,
    },
  };

  const handleError = useCallback(
    (error: Error) => {
      const apiError = error as ApiError;
      const httpStatus = apiError.status; // HTTP Status

      //http status error
      if (!!defaultHandlers[httpStatus]) {
        const ret = appendErrorCode(httpStatus);
        if (ret) {
          console.log(apiErrorCurrentCodes);
          defaultHandlers[httpStatus].default();
        }

        return true;
      }

      const ApiResponseError = apiError.body;
      if (
        !!ApiResponseError?.code &&
        ApiResponseError?.code !== API_SUCCESS_CODE
      ) {
        defaultHandlers['businessError'].default(apiError);

        return true;
      }

      return false;
    },
    [handlers],
  );

  // ...
  return { handleError };
};
