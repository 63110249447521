import { BoldOutlined, InstagramOutlined } from '@ant-design/icons';
import { APP_PREFIX_PATH, EXTERNAL_PATH } from '@src/constants/constant';
import { NavigationTypes } from '@src/types/types';

import {
  IcoChartSquare,
  IcoHashtag,
  IcoMap,
  IcoMenuBoard,
  IcoPayment,
  IcoSetting,
  IcoStoreFront,
  IcoSound,
  IcoSubscription,
  IcoTickSquare,
  IcoWalletMoney,
} from '@src/assets/svg';

const homeNavTree: NavigationTypes[] = [
  {
    key: 'main',
    path: `${APP_PREFIX_PATH}/analysis/main`,
    title: '종합 분석',
    icon: IcoMenuBoard,
    breadcrumb: true,
    submenu: [],
  },
];

const growthTrendNavTree: NavigationTypes[] = [
  {
    key: 'growthTrend',
    path: `${APP_PREFIX_PATH}/analysis/growth-trend`,
    title: '성장 추이',
    icon: IcoTickSquare,
    breadcrumb: true,
    submenu: [],
  },
];

const naverKeywordSearchVolumeNavTree: NavigationTypes[] = [
  {
    key: 'naverKeywordSearchVolume',
    path: `${APP_PREFIX_PATH}/analysis/naver-keyword-search-volume`,
    title: '네이버 키워드 검색량',
    icon: IcoWalletMoney,
    breadcrumb: true,
    submenu: [],
  },
];

const homepageAnalysisNavTree: NavigationTypes[] = [
  {
    key: 'homepageAnalysis',
    path: `${APP_PREFIX_PATH}/analysis/homepage`,
    title: '홈페이지 분석',
    icon: IcoChartSquare,
    breadcrumb: true,
    submenu: [],
  },
];

const snsChannelNavTree: NavigationTypes[] = [
  {
    key: 'snsChannel',
    path: `${APP_PREFIX_PATH}/analysis/sns-channel`,
    title: 'SNS채널 분석',
    icon: IcoHashtag,
    breadcrumb: true,
    isOpen: true,
    submenu: [
      {
        key: 'snsChannel-blog',
        path: `${APP_PREFIX_PATH}/analysis/sns-channel/blog`,
        title: 'Blog',
        icon: BoldOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'snsChannel-instargram',
        path: `${APP_PREFIX_PATH}/analysis/sns-channel/instargram`,
        title: 'Instargram',
        icon: InstagramOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const smartStoreAnalysisNavTree: NavigationTypes[] = [
  {
    key: 'smartStoreAnalysis',
    path: `${APP_PREFIX_PATH}/analysis/smart-store`,
    title: '스마트 스토어 분석',
    icon: IcoStoreFront,
    breadcrumb: true,
    submenu: [],
  },
];

const smartPlaceAnalysisNavTree: NavigationTypes[] = [
  {
    key: 'smartPlaceAnalysis',
    path: `${APP_PREFIX_PATH}/analysis/smart-place`,
    title: '스마트 플레이스 분석',
    icon: IcoMap,
    breadcrumb: true,
    submenu: [],
  },
];

const keywordNewsNavTree: NavigationTypes[] = [
  {
    key: 'keywordNews',
    path: `${APP_PREFIX_PATH}/my-news/keyword-news`,
    title: '키워드 소식',
    icon: IcoSound,
    breadcrumb: true,
    submenu: [],
  },
];

const aiSummaryNavTree: NavigationTypes[] = [
  {
    key: 'AISummary',
    path: `${EXTERNAL_PATH}/ai-summary`,
    title: '실시간 검색어 AI 요약',
    icon: IcoSound,
    breadcrumb: false,
    submenu: [],
  },
];

const settingsNavTree: NavigationTypes[] = [
  {
    key: 'settings',
    isBottomMenu: true,
    path: `${APP_PREFIX_PATH}/setting`,
    title: '설정',
    icon: IcoSetting,
    breadcrumb: true,
    submenu: [
      {
        key: 'settings-subscription',
        path: `${APP_PREFIX_PATH}/setting/subscription`,
        title: '서비스 구독',
        icon: IcoSubscription,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'settings-payment',
        path: `${APP_PREFIX_PATH}/setting/payment`,
        title: '결제 관리',
        icon: IcoPayment,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];
/*
const consultingNavTree: NavigationTypes[] = [
  {
    key: 'consulting',
    path: `${APP_PREFIX_PATH}/consulting`,
    title: '무료상담',
    icon: ExclamationCircleOutlined,
    breadcrumb: true,
    submenu: [],
  },
];
 */

const navigationConfig = [
  // ...homeNavTree,
  // ...growthTrendNavTree,
  // ...naverKeywordSearchVolumeNavTree,
  // ...homepageAnalysisNavTree,
  // ...snsChannelNavTree,
  // ...smartStoreAnalysisNavTree,
  // ...smartPlaceAnalysisNavTree,
  ...keywordNewsNavTree,
  ...aiSummaryNavTree,
  //...consultingNavTree,
];

export default [...navigationConfig];
export { navigationConfig };
