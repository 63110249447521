import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';

import MainLayout from '@src/layouts/MainLayout';
import AppLayout from '@src/layouts/AppLayout';
import ExternalLayout from '@src/layouts/ExternalLayout';

import {
  APP_PREFIX_PATH,
  EXTERNAL_PATH,
  MAIN_PATH,
} from '@src/constants/constant';
import { useApiError } from '@hooks/api/error';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Loading } from '@components/shared';
import { useAtom } from 'jotai';
import { authAtom } from '@src/store/auth';

export const Views = () => {
  const { handleError } = useApiError();
  const [authInfo, setAuthInfo] = useAtom(authAtom);

  const queryOnError = async (error: unknown) => {
    const ret = handleError(error as Error);

    const errorsKeys = queryClient
      .getQueryCache()
      .getAll() // react-query의 query cache에서
      .filter((q) => q.state.status === 'error') // error를 캐싱한 것만 골라
      .map((e) => e.queryKey);

    await queryClient.invalidateQueries(errorsKeys);

    if (ret) {
      throw error;
    }
  };

  useEffect(() => {
    ReactGA.initialize('G-LN0GMRMX4C');
  }, []);
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location]);

  // Create a client
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: 0,
            staleTime: 5000,
            cacheTime: Infinity,
            suspense: true,
            useErrorBoundary: true,
          },
        },
        queryCache: new QueryCache({
          onError: queryOnError,
        }),
        //mutationCache: new MutationCache({
        //  onError: queryOnError,
        //}),
      }),
    [],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <Routes>
          <Route path={`${APP_PREFIX_PATH}/*`} element={<AppLayout />} />
          <Route path={`${EXTERNAL_PATH}/*`} element={<ExternalLayout />} />
          <Route path={`/*`} element={<MainLayout />} />
        </Routes>
        {process.env.NODE_ENV !== 'production' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </>
    </QueryClientProvider>
  );
};

export default Views;
