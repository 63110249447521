import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import FormData from 'form-data';

import { ApiError } from '@src/api/core/ApiError';
import type { ApiRequestOptions } from '@src/api/core/ApiRequestOptions';
import type { ApiResult } from '@src/api/core/ApiResult';
import type { OnCancel } from '@src/api/core/CancelablePromise';
import { CancelablePromise } from '@src/api/core/CancelablePromise';
import type { OpenAPIConfig } from '@src/api/core/OpenAPI';

import Utils from '@src/utils/utils';
import { ApiResponse } from '@src/api/custom/models/ApiResponse';
import { useGetAccessToken } from '@hooks/auth';

axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const config = error.config;

    if (error.response?.status === 401 && !config._retry) {
      config._retry = true;
    }
    return Promise.reject(error);
  },
);

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;

const resolve = async <T>(
  options: ApiRequestOptions,
  resolver?: T | Resolver<T>,
): Promise<T | undefined> => {
  if (typeof resolver === 'function') {
    return (resolver as Resolver<T>)(options);
  }
  return resolver;
};

const getHeaders = async (
  config: OpenAPIConfig,
  options: ApiRequestOptions,
  formData?: FormData,
): Promise<Record<string, string>> => {
  const token = await resolve(options, config.TOKEN);
  const username = await resolve(options, config.USERNAME);
  const password = await resolve(options, config.PASSWORD);
  const additionalHeaders = await resolve(options, config.HEADERS);
  const formHeaders =
    (typeof formData?.getHeaders === 'function' && formData?.getHeaders()) ||
    {};

  const headers = Object.entries({
    Accept: 'application/json',
    ...additionalHeaders,
    ...options.headers,
    ...formHeaders,
  })
    .filter(([_, value]) => Utils.isDefined(value))
    .reduce(
      (headers, [key, value]) => ({
        ...headers,
        [key]: String(value),
      }),
      {} as Record<string, string>,
    );

  try {
    const { accessToken: localToken } = JSON.parse(
      window.localStorage.getItem('auth-info') || '{}',
    );
    if (localToken && options.url !== '/auth/binder/kakao/token') {
      headers['Authorization'] = `Bearer ${localToken}`;
    }
  } catch (e) {}

  if (Utils.isStringWithValue(username) && Utils.isStringWithValue(password)) {
    const credentials = Utils.base64(`${username}:${password}`);
    headers['Authorization'] = `Basic ${credentials}`;
  }

  if (options.body) {
    if (options.mediaType) {
      headers['Content-Type'] = options.mediaType;
    } else if (Utils.isBlob(options.body)) {
      headers['Content-Type'] = options.body.type || 'application/octet-stream';
    } else if (Utils.isString(options.body)) {
      headers['Content-Type'] = 'text/plain';
    } else if (!Utils.isFormData(options.body)) {
      headers['Content-Type'] = 'application/json';
    }
  }

  return headers;
};

const getRequestBody = (options: ApiRequestOptions): any => {
  if (options.body) {
    return options.body;
  }
  return undefined;
};

const sendRequest = async <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions,
  url: string,
  body: any,
  formData: FormData | undefined,
  headers: Record<string, string>,
  onCancel: OnCancel,
): Promise<AxiosResponse<T>> => {
  const source = axios.CancelToken.source();

  const requestConfig: AxiosRequestConfig = {
    url,
    headers,
    data: body ?? formData,
    method: options.method,
    withCredentials: config.WITH_CREDENTIALS,
    cancelToken: source.token,
  };

  onCancel(() => source.cancel('The user aborted a request.'));

  try {
    return await axios.request(requestConfig);
  } catch (error) {
    const axiosError = error as AxiosError<T>;
    if (axiosError.response) {
      return axiosError.response;
    }
    throw error;
  }
};

const getResponseHeader = (
  response: AxiosResponse<any>,
  responseHeader?: string,
): string | undefined => {
  if (responseHeader) {
    const content = response.headers[responseHeader];
    if (Utils.isString(content)) {
      return content;
    }
  }
  return undefined;
};

const getResponseBody = (response: AxiosResponse<any>): any => {
  if (response.status !== 204) {
    return response.data;
  }
  return undefined;
};

const catchErrorCodes = (
  options: ApiRequestOptions,
  result: ApiResult,
): void => {
  const errors: Record<number, string> = {
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    ...options.errors,
  };

  const error = errors[result.status];
  if (error) {
    throw new ApiError(options, result, error);
  }

  if (!result.ok) {
    throw new ApiError(options, result, 'Generic Error');
  }
};

const catchBusinessErrorCodes = (
  options: ApiRequestOptions,
  result: ApiResult,
): void => {
  const errors: Record<string, string> = {
    ...options.errors,
  };

  const response = result.body as ApiResponse;
  if (response.code !== 'OK') {
    const error = errors[response.code];
    if (error) {
      throw new ApiError(options, result, error);
    }
  }
};

/**
 * Request method
 * @param config The OpenAPI configuration object
 * @param options The request options from the service
 * @returns CancelablePromise<T>
 * @throws ApiError
 */
export const request = <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions,
): CancelablePromise<T> => {
  return new CancelablePromise(async (resolve, reject, onCancel) => {
    try {
      const url = Utils.getUrl(config, options);
      const formData = Utils.getFormData(options);
      const body = getRequestBody(options);
      const headers = await getHeaders(config, options, formData);

      if (!onCancel.isCancelled) {
        const response = await sendRequest<T>(
          config,
          options,
          url,
          body,
          formData,
          headers,
          onCancel,
        );
        const responseBody = getResponseBody(response);

        const result: ApiResult = {
          url,
          ok: Utils.isSuccess(response.status),
          status: response.status,
          statusText: response.statusText,
          body: responseBody,
        };

        catchErrorCodes(options, result);
        catchBusinessErrorCodes(options, result);

        resolve(result.body);
      }
    } catch (error) {
      reject(error);
    }
  });
};
