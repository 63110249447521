import React, { lazy } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { FallbackProps } from 'react-error-boundary';

import { Loading } from '@components/shared';
import PageError from '@components/shared/PageError';
import AsyncBoundary from '@src/components/shared/AsyncBoundary';
import { APP_HOME_PATH, MAIN_PATH } from '@src/constants/constant';
import SettingsMy from '@src/views/setting/SettingMy';

const MainAnalysis = lazy(() => import(`@views/mainAnalysis/MainAnalysis`));
const Error404 = lazy(() => import(`@views/errors/Error404`));
const ChannelNaverBlog = lazy(
  () => import(`@views/snsChannel/ChannelNaverBlog`),
);
const ChannelInstargram = lazy(
  () => import(`@views/snsChannel/ChannelInstargram`),
);
const SmartStore = lazy(() => import(`@views/smartStore/SmartStore`));
const SmartPlace = lazy(() => import(`@views/smartPlace/SmartPlace`));
const HomepageAnalysis = lazy(
  () => import(`@views/homepageAnalysis/HomepageAnalysis`),
);
const GrowthTrend = lazy(() => import(`@views/growthTrend/GrowthTrend`));

const NaverKeywordSearchVolume = lazy(
  () => import(`@views/naverKeywordSearchVolume/NaverKeywordSearchVolume`),
);

const Consulting = lazy(() => import(`@views/consulting/Consulting`));
const KeywordNews = lazy(() => import(`@views/keywordNews/KeywordNews`));
const SettingSubscription = lazy(
  () => import(`@src/views/setting/SettingSubscription`),
);
const SettingPaymentCards = lazy(
  () => import(`@src/views/setting/SettingPaymentCards`),
);
// const SettingPaymentNewCard = lazy(
//   () => import(`@src/views/setting/SettingPaymentNewCards`),
// );

export const AppViews = () => {
  const location = useLocation();

  return (
    <AsyncBoundary
      ErrorFallback={(fallbackProps: FallbackProps) => (
        <PageError fallbackProps={fallbackProps} />
      )}
      SuspenseFallback={<Loading cover="content" />}
      resetKeys={[location.pathname]}
    >
      <Routes>
        <Route path="/my" element={<SettingsMy />} />
        <Route path="/*" element={<KeywordNews />} />
      </Routes>
    </AsyncBoundary>
  );
};

export default React.memo(AppViews);
